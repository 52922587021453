import { makeAutoObservable } from "mobx";
import { createContext, useContext } from "react";
import ChannelPartnerService from "../Services/ChannelPartnerService";

/**
 * @typedef Session
 * @property {string} Name
 * @property {string} ProfilePhotoBase64
 * @property {string} ProfilePhotoType
 * @property {string} BearerToken
 */

/** @type {SessionStore} */
var SessionStoreInstance = null;

const SessionStoreContext = createContext(null);

export default class SessionStore {
	constructor(config) {
		/** @type { Session } */
		this.Session = this.LoadPreviousSession();
		this.Config = config;

		//services
		this.ChannelPartnerService = new ChannelPartnerService(config.apiUrl, this);
		
		makeAutoObservable(this);
	}

	LoadPreviousSession() {
		var sessionData = localStorage.getItem("Session");

		if(sessionData) {
			return JSON.parse(sessionData);
		}

		return null;
	}

	SetSession(session) {
		this.Session = session;
		
		localStorage.setItem("Session", JSON.stringify(session));
	}

	Logout() {		
		localStorage.clear();
		sessionStorage.clear();

		this.Session = null;
		document.location.href= "/";
	}

	get UserIsLoggedIn () {
        return this.Session !== null && this.Session.User !== null;
    }
}

export function SessionStoreProvider(props) {
    if(SessionStoreInstance === null) {
        SessionStoreInstance = new SessionStore(props.config);
    }

    return <SessionStoreContext.Provider value={ SessionStoreInstance }>{ props.children }</SessionStoreContext.Provider>
}

/**
 * @returns {SessionStore}
 */
export function useSessionStore() {
    const context = useContext(SessionStoreContext);

    return context;
}