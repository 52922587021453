import Headerbar from "./Headerbar";

export default function Page({ icon, title, children }) {
	return 	<div 
				className="h-100 overflow-y-auto overflow-x-hidden m-0" 
				style={{ paddingBottom: "80px" }}
			>
				<Headerbar 
					icon={ icon } 
					title={ title }
				/>
				{
					children
				}
			</div>
}