import { Alert, Button, Modal } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { useEffect, useState } from "react";
import Spinner from "../../Common/Spinner";

export default function AvailableBandwidthModal({ show, clientServiceId, onClose }) {
	const store = useSessionStore();
	const [availableData, setAvailableData] = useState(null);

	async function getAvailableData() {
		setAvailableData(null);
		const result = await store.ChannelPartnerService.GetAvailableBandwidth(clientServiceId);

		if(result && result.Success) {
			setAvailableData(result.Data);
		}
	}

	useEffect(() => {
		if(show) {
			getAvailableData();
		}
	}, [show])

	return 	<Modal show={show}>
				<Modal.Header>
					<h4>Available Data</h4>
				</Modal.Header>
				<Modal.Body>
					{
						availableData &&
						<Alert className="mb-0">
							<span>Available: {availableData.Bandwidth}{availableData.Unit}</span>
						</Alert>
					}
					<Spinner show={ !availableData } />
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={ onClose }>Close</Button>
				</Modal.Footer>
			</Modal>
}