import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";

export default function Spinner({ show }) {
	if(show) {
		return 	<Row>
					<Col xs={12} className="d-flex justify-content-center p-5">
						<FontAwesomeIcon icon={ faRefresh } spin size="2x" />
					</Col>
				</Row>
	} else {
		return null;
	}
}