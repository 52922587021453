import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { useSessionStore } from "../../../Stores/SessionStore";
import Panel from "../../Common/Panel";
import { Col, Row, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import Pagination from "../../Common/Pagination";
import Spinner from "../../Common/Spinner";
import Page from "../../Common/Page";
import Search from "../../Common/Search";

export default function OrdersIndex() {
	const store = useSessionStore();
	
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [orders, setOrders] = useState(null);
	const [pagination, setPagination] = useState({
		PageIndex: 0,
		PageCount: 10,
		Total: 0
	});

	async function getOrders(pageIndex, pageCount) {
		setLoading(true);
		const results = await store.ChannelPartnerService.GetOrders(search, pageIndex, pageCount);
		
		if(results && results.Success) {
			setOrders(results.Data);
			setPagination({
				PageCount: pageCount,
				PageIndex: pageIndex,
				Total: results.Total
			});
			setLoading(false);
		}
	}

	useEffect(() => {
		getOrders(0, 10);
	}, []);

	function handlePageChanged(pageIndex, pageCount) {
		getOrders(pageIndex, pageCount);
	}

	return <Page
				icon={ faBoxes } 
				title="Orders"
			>
				<Panel title="Viewing Active Orders">
					<Search 
						disabled={ loading }
						onSearch={ () => getOrders(pagination.PageIndex, pagination.PageCount) }
						search={ search }
						setSearch={ setSearch }
					/>
					<Row>
						<Col>
							<Table className="mb-0">
								<thead>
									<tr>
										<th>Client</th>
										<th>ID</th>
										<th>Service</th>
										<th>Site Name</th>
										<th>Ordered Date</th>
									</tr>
								</thead>
								<tbody>
									{
										!loading && orders && orders.map(o => {
											return 	<tr>
														<td>{ o.Client }</td>
														<td>TC-{ o.ClientServiceID }</td>
														<td>{ o.Service }</td>
														<td>{ o.SiteName }</td>
														<td>{ DateTime.fromISO(o.OrderDate).toFormat("dd MMMM yyyy") }</td>
													</tr>
										})
									}
								</tbody>
							</Table>
							{
								!loading &&
								<Pagination 
									filter={ pagination } 
									onPageChanged={ handlePageChanged } 
									totalResults={ orders ? orders.length : 0 }
								/>
							}
							<Spinner show={ loading } />
						</Col>
					</Row>
				</Panel>
			</Page>
}