import { Card, Col, Row } from "react-bootstrap";

export default function Panel({ children, title }) {
	return 	<Card className="mt-3 mx-3 bg-white p-0">
				<Card.Body className="p-0">
					<Row className="p-3">
						<Col><h5>{ title }</h5></Col>
					</Row>
					<Row>
						<Col>
							{ children }
						</Col>
					</Row>
				</Card.Body>
			</Card>
}