import APIBase from "./APIBase"

export default class ChannelPartnerService extends APIBase {
	constructor(apiUrl, session) {
		super(apiUrl, session)
	}

	Login(username, password, recaptchaValue) {
		return this.Post(`Login`, { 
			Username: username,
			Password: password,
			RecaptchaValue: recaptchaValue
		});
	}

	GetOpportunities(search, pageIndex, pageCount) {
		return this.Get(`GetOpportunities?search=${encodeURIComponent(search)}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	GetOrders(search, pageIndex, pageCount) {
		return this.Get(`GetOrders?search=${encodeURIComponent(search)}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	GetServices(search, pageIndex, pageCount) {
		return this.Get(`GetServices?search=${encodeURIComponent(search)}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	LogSupportTicket(faultReport) {
		return this.Post(`LogSupportTicket`, faultReport);
	}

	GetAvailableBandwidth(clientServiceId) {
		return this.Get(`GetAvailableBandwidth?clientServiceId=${clientServiceId}`);
	}

	GetNetworkStats(clientServiceId, dateRange) {
		return this.Get(`GetNetworkStats?clientServiceId=${clientServiceId}&dateRange=${dateRange}`);
	}

	ForgotPassword(emailAddress) {
		return this.Post(`ForgotPassword?emailAddress=${encodeURIComponent(emailAddress)}`, null);
	}

	ResetPassword(model) {
		return this.Post(`ResetPassword`, model);
	}
}