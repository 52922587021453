import { Col, Nav, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes, faTrophy, faWrench } from "@fortawesome/free-solid-svg-icons";
import { useSessionStore } from "../../Stores/SessionStore";

export default function Sidebar() {
	const store = useSessionStore();

	const activeSidebarItemStyle = { backgroundColor: "rgb(245, 245, 245)" };
    const sidebarItemStyle = { color: "#ffffffaa", fontSize: "14px", fontFamily: "sans-serif", textDecoration: "none", display: "block", width: "100%", padding: "10px 10px 10px 15px"};
	const sidebarStyle = { display: "block", zIndex: "1000", width: "260px", position: "fixed" };

	return  <Nav className="h-100 bg-dark pt-2" style={ sidebarStyle }>
				<Row>
					<Col className="text-white py-4" style={{ paddingLeft: "30px" }}>
						{ store.Session.Name }
					</Col>
				</Row>
				<Nav.Item style={{ width: "100%" }}>
					<NavLink to="/Opportunities" style={ sidebarItemStyle } activeStyle={ activeSidebarItemStyle }>
						<FontAwesomeIcon color="#ffffffaa" icon={ faTrophy } style={{ width: "24px" }} /> <span style={{ marginLeft: "10px" }}>Opportunities</span>
					</NavLink>
				</Nav.Item>
				<Nav.Item style={{ width: "100%" }}>
					<NavLink to="/Orders" style={ sidebarItemStyle } activeStyle={ activeSidebarItemStyle }>
						<FontAwesomeIcon color="#ffffffaa" icon={ faBoxes } style={{ width: "24px" }} /> <span style={{ marginLeft: "10px" }}>Orders</span>
					</NavLink>
				</Nav.Item>
				<Nav.Item style={{ width: "100%" }}>
					<NavLink to="/Services" style={ sidebarItemStyle } activeStyle={ activeSidebarItemStyle }>
						<FontAwesomeIcon color="#ffffffaa" icon={ faWrench } style={{ width: "24px" }} /> <span style={{ marginLeft: "10px" }}>Services</span>
					</NavLink>
				</Nav.Item>
            </Nav>
}