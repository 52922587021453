import { Button, Card, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { useEffect, useState } from "react";
import logo from "../../Images/logo.png"
import { useSessionStore } from "../../Stores/SessionStore";

export default function Login() {
	const store = useSessionStore();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const [loggingIn, setLoggingIn] = useState(false);
	const [error, setError] = useState("");

	async function onSubmitClicked() {
		if(!username || !password) {
			setError("Username and Password required.");
			return;
		}

		setError("");
		setLoggingIn(true);

		window.grecaptcha.ready(function() {
			window.grecaptcha.execute('6LdOkVcqAAAAALHuiNSTFfn9AC3zHCNYjJJ6-fN0', {action: 'submit'}).then(async function(token) {
				const result = await store.ChannelPartnerService.Login(username, password, token);
				setLoggingIn(false);

				if(result) {
					if(result.Success) {
						const scriptTag = document.getElementById("GoogleCaptcha");
						if(scriptTag) {
							scriptTag.remove();
						}

						const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
						if (recaptchaElems.length) {
							recaptchaElems[0].remove();
						}

						store.SetSession(result.Data);
					} else {
						setError(result.Message);
					}
				} else {
					setError("An error ocurred attempting to login. Please try again later.");
				}
			});
		});
	}

	useEffect(() => {
		let scriptTag = document.getElementById("GoogleCaptcha");

		if(!scriptTag) {
			scriptTag = document.createElement("script");
			scriptTag.setAttribute("src", `https://www.google.com/recaptcha/api.js?render=6LdOkVcqAAAAALHuiNSTFfn9AC3zHCNYjJJ6-fN0`);
			scriptTag.setAttribute("id", "GoogleCaptcha");
	
			document.head.appendChild(scriptTag);
		}
	}, []);

	function handleKeyDown(key) {
		if(key === "Enter") {
			onSubmitClicked();
		}
	}
	
	return 	<Container fluid>
				<Row>
					<Col className="d-flex justify-content-center">
						<Card className="mt-5 bg-white p-2 pt-4">
							<Card.Body className="text-center">
								<img src={ logo } alt="Logo" style={{ height: "48px" }}/>
								<div>
									<h5 className="mt-3">Login to your account</h5>
								</div>
								<div className="mb-3">
									<small className="text-black-50">Enter your credentials</small>
								</div>
								<Form>
									<Stack gap={3}>
										<Form.Control value={ username } onChange={ e => setUsername(e.target.value) } disabled={ loggingIn } placeholder="Username" />
										<Form.Control type="password" value={ password } onKeyDown={ e => handleKeyDown(e.key) } onChange={ e => setPassword(e.target.value) } disabled={ loggingIn } placeholder="Password" />
										<small className="text-danger">{ error }</small>
										<Button onClick={ onSubmitClicked } disabled={ loggingIn }>{ loggingIn ? "Logging In..." : "Login" }</Button>
										<small><a className="text-decoration-none" href="#/forgot-password">Forgot Password?</a></small>
									</Stack>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
}