import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";

export default function Headerbar({ title, icon }) {
	return 	<Row className="bg-white border-bottom">
				<Col>
					<h4 className="py-4 px-3">
						<FontAwesomeIcon size="sm" className="me-1" icon={ icon } />
						{title}
					</h4>
				</Col>
			</Row>
}