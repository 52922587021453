import { observer } from "mobx-react-lite";
import { useSessionStore } from "../Stores/SessionStore";
import Main from "./Main/Main";
import Auth from "./Auth/Auth";

const App = observer(function App() {
	const sessionStore = useSessionStore();

	if(sessionStore.UserIsLoggedIn) {
		return <Main />
	} else {
		return <Auth />
	}
});

export default App;