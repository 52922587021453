import { Col, Dropdown, Row } from "react-bootstrap";
import logo from "../../Images/logo_light.png"
import { useSessionStore } from "../../Stores/SessionStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

export default function TopBar() {
	const store = useSessionStore();

	return 	<Row className="bg-primary p-3">
				<Col>
					<img src={ logo } alt="Logo" style={{ height: "32px" }} />
				</Col>
				<Col className="d-flex justify-content-end">
					<Dropdown>
						<Dropdown.Toggle>
							{ store.Session.Name }
						</Dropdown.Toggle>
						<Dropdown.Menu>						
							<Dropdown.Item onClick={ () => store.Logout() }>
								<FontAwesomeIcon icon={ faPowerOff } className="pe-2"/>
								Logout
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</Col>
			</Row>
}