import { Button, Form, Modal, Alert } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { useEffect, useState } from "react";

export default function LogSupportTicketModal({ show, clientServiceId, onClose }) {
	const store = useSessionStore();

	const [description, setDescription] = useState("");
	const [contactDetails, setContactDetails] = useState("");

	const [submitting, setSubmitting] = useState(false);
	const [showError, setShowError] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	
	async function submitSupportTicket() {
		if(!description || !contactDetails) {
			return;
		}

		setShowSuccess(false);
		setShowError(false);
		setSubmitting(true);

		const result = await store.ChannelPartnerService.LogSupportTicket({
			FaultDescription: description,
			ContactDetails: contactDetails,
			ClientServiceId: clientServiceId
		});

		setSubmitting(false);
		setDescription("");
		setContactDetails("");

		if(result && result.Success) {
			setShowSuccess(true);
		} else {
			setShowError(true);
		}
	}

	useEffect(() => {
		if(show) {
			setShowSuccess(false);
			setShowError(false);
			setDescription("");
			setContactDetails("");
		}
	}, [show]);

	return 	<Modal show={ show }>
				<Modal.Header>
					<h4>Log Support Ticket: TC-{clientServiceId}</h4>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group>
							<Form.Label>Contact Details*</Form.Label>
							<Form.Control 
								value={ contactDetails } 
								onChange={ e => setContactDetails(e.target.value) }
							/>
						</Form.Group>
						<Form.Group className="mt-3">
							<Form.Label>Fault Description*</Form.Label>
							<Form.Control 
								value={ description } 
								onChange={ e => setDescription(e.target.value) }
								as="textarea"
								rows={5}
							/>
						</Form.Group>
					</Form>
					{
						showSuccess && !showError &&
						<Alert className="mb-0 mt-3" variant="success">Your support ticket has been logged. EdgeConnect support staff will reach out to you shortly.</Alert>
					}
					{
						!showSuccess && showError &&
						<Alert className="mb-0 mt-3" variant="danger">An error ocurred attempting to submit your support ticket. Please try again later.</Alert>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button 
						disabled={ submitting || !description || !contactDetails} 
						onClick={ submitSupportTicket }
					>
						{ submitting ? "Submitting..." : "Submit" }
					</Button>
					<Button
						disabled={ submitting }  
						onClick={ onClose }
					>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
}