import { faCheck, faTimes, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { useSessionStore } from "../../../Stores/SessionStore";
import Panel from "../../Common/Panel";
import { Col, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../Common/Pagination";
import Spinner from "../../Common/Spinner";
import Page from "../../Common/Page";
import Search from "../../Common/Search";

export default function OpportunitiesIndex() {
	const store = useSessionStore();
	
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [opportunities, setOpportunities] = useState(null);
	const [pagination, setPagination] = useState({
		PageIndex: 0,
		PageCount: 10,
		Total: 0
	});

	async function getOpportunities(pageIndex, pageCount) {
		setLoading(true);
		const results = await store.ChannelPartnerService.GetOpportunities(search, pageIndex, pageCount);
		
		if(results && results.Success) {
			setOpportunities(results.Data);
			setPagination({
				PageCount: pageCount,
				PageIndex: pageIndex,
				Total: results.Total
			});
			setLoading(false);
		}
	}

	useEffect(() => {
		getOpportunities(0, 10);
	}, []);

	function getIndicator(item) {
		if (item === "Waiting") {
			return <strong className="text-info">Waiting</strong>
		} else if (item === "Feasible") {
			return <span className="text-success"><FontAwesomeIcon icon={ faCheck } /></span>
		} else if (item === "Not Feasible") {
			return <span className="text-danger"><FontAwesomeIcon icon={ faTimes } /></span>
		}
	}

	function getPercentage(opportunity) {
		let colour = "success";
		let percentage = "0%";

		if (opportunity.OpportunityStatusID === 1) {
			colour = "success";
			percentage = "100%";
		}

		if (opportunity.OpportunityStatusID === 2) {
			colour = "danger";
			percentage = "0%";
		}

		if (opportunity.OpportunityStatusID === 3) {
			colour = "yellow";
			percentage = "50%";
		}

		if (opportunity.OpportunityStatusID === 4) {
			colour = "warning";
			percentage = "80%";
		}

		if (opportunity.OpportunityStatusID === 5) {
			colour = "info";
			percentage = "10%";
		}

		return {
			colour, percentage
		};
	}

	function handlePageChanged(pageIndex, pageCount) {
		getOpportunities(pageIndex, pageCount);
	}

	return <Page
				icon={ faTrophy } 
				title="Opportunities"
			>
				<Panel title="Viewing Active Opportunities">
					<Search 
						disabled={ loading }
						onSearch={ () => getOpportunities(pagination.PageIndex, pagination.PageCount) }
						search={ search }
						setSearch={ setSearch }
					/>
					<Row>
						<Col>
							<Table className="mb-0">
								<thead>
									<tr>
										<th style={{ width: '70px' }}>&nbsp;</th>
										<th>Name</th>
										<th style={{ width: '200px' }}>Site Address</th>
										<th style={{ width: '50px' }} className="text-center">Connected Fibre</th>
										<th style={{ width: '50px' }} className="text-center">Broadband Fibre</th>
										<th style={{ width: '50px' }} className="text-center">Enterprise FF</th>
										<th style={{ width: '50px' }} className="text-center">Enterprise MFN</th>
										<th style={{ width: '50px' }} className="text-center">Premium Off Net</th>
										<th style={{ width: '50px' }} className="text-center">Microwave</th>
										<th style={{ width: '50px' }} className="text-center">Consumer</th>
										<th style={{ width: '50px' }} className="text-center">Liquid</th>
									</tr>
								</thead>
								<tbody>
									{
										!loading && opportunities && opportunities.map(o => {
											const data = getPercentage(o);
											return 	<tr>
														<td>
															<div className="text-center">
																<OverlayTrigger overlay={<Tooltip id={o.OpportunityID}>{ o.Description ?? "No Description" }</Tooltip>}>
																	<span style={{ cursor: "pointer" }} className={ `bg-${data.colour} rounded-5 text-white p-2` }>
																		{ data.percentage }
																	</span>
																</OverlayTrigger>
															</div>
														</td>
														<td>
															{ o.Name } <br /> { DateTime.fromISO(o.DateTimeStamp).toFormat("dd MMMM yyyy") }
														</td>
														<td>{ o.SiteAddress }</td>
														<td className="text-center">
															{ getIndicator(o.ConnectFibre) }
														</td>
														<td className="text-center">
															{ getIndicator(o.ConnectFibre) }
														</td>
														<td className="text-center">
															{ getIndicator(o.ConnectEnterprise) }
														</td>
														<td className="text-center">
															{ getIndicator(o.EnterpriseMFN) }
														</td>
														<td className="text-center">
															{ getIndicator(o.ConnectWireless) }
														</td>
														<td className="text-center">
															{ getIndicator(o.ConnectOpenserve) }
														</td>
														<td className="text-center">
															{ getIndicator(o.HomeFibre) }
														</td>
														<td className="text-center">
															{ getIndicator(o.Liquid) }
														</td>
													</tr>
										})
									}
								</tbody>
							</Table>
							{
								!loading &&
								<Pagination 
									filter={ pagination } 
									onPageChanged={ handlePageChanged } 
									totalResults={ opportunities ? opportunities.length : 0 }
								/>
							}
							<Spinner show={ loading } />
						</Col>
					</Row>
				</Panel>
			</Page>
}