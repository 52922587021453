import { Button, Card, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { useState } from "react";
import logo from "../../Images/logo.png"
import { useSessionStore } from "../../Stores/SessionStore";
import { useParams } from "react-router-dom";

export default function ResetPassword() {
	const store = useSessionStore();
	const params = useParams();

	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [success, setSuccess] = useState(false);

	async function onSubmitClicked() {
		if(!password || !passwordConfirm) {
			return;
		}

		if(password !== passwordConfirm) {
			setError(true);
			setErrorMessage("Password must match password confirmation.");

			return;
		}

		setError(false);
		
		setSubmitting(true);
		const result = await store.ChannelPartnerService.ResetPassword({
			Password: password,
			ConfirmPassword: passwordConfirm,
			Code: params.code
		});

		setSubmitting(false);

		if(result && result.Success) {
			setSuccess(true);
		} else {
			setError(true);

			if(result && result.Message) {
				setErrorMessage(result.Message);
			}
		}
	}
	
	return 	<Container fluid>
				<Row>
					<Col className="d-flex justify-content-center">
						<Card className="mt-5 bg-white p-2 pt-4" style={{ width: "360px" }}>
							<Card.Body className="text-center">
								<img src={ logo } alt="Logo" style={{ height: "48px" }}/>
								<div>
									<h5 className="mt-3">{ success ? "Reset password confirmation" : "Reset your Password" }</h5>
								</div>
								<div className="mb-3">
									<small className="text-black-50">{ success ? "Your password has been reset." : "Enter your new password." }</small>
								</div>
								<Form>
									<Stack gap={3}>
										{
											!success &&
											<>
												<Form.Control 
													type="password" 
													value={ password } 
													onChange={ e => setPassword(e.target.value) } 
													disabled={ submitting } 
													placeholder="Password" 
												/>
												<Form.Control 
													type="password" 
													value={ passwordConfirm } 
													onChange={ e => setPasswordConfirm(e.target.value) } 
													disabled={ submitting } 
													placeholder="Confirm Password"
												/>
												{
													error &&
													<small className="text-danger">
														An error ocurred attempting to reset your password. { errorMessage ? errorMessage : "Please try again later." }
													</small>
												}
												<Button 
													onClick={ onSubmitClicked } 
													disabled={ submitting }
												>
													{ submitting ? "Resetting..." : "Reset Password" }
												</Button>
											</>
										}
										<Button 
											onClick={ () => document.location.href = "/" } 
											disabled={ submitting }
											variant="light"
											className="border"
										>
											Back
										</Button>
									</Stack>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
}