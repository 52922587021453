import { Button, Card, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { useState } from "react";
import logo from "../../Images/logo.png"
import { useSessionStore } from "../../Stores/SessionStore";

export default function ForgotPassword() {
	const store = useSessionStore();
	
	const [username, setUsername] = useState("");
	const [submitting, setSubmitting] = useState(false);
	const [showError, setShowError] = useState("");
	const [showSuccess, setShowSuccess] = useState("");

	async function onSubmitClicked() {
		if(!username) {
			return false;
		}

		setShowError(false);
		setShowSuccess(false);

		setSubmitting(true);
		const result = await store.ChannelPartnerService.ForgotPassword(username);
		setSubmitting(false);

		if(result && result.Success) {
			setShowSuccess(true);
		} else {
			setShowError(true);
		}
	}
	
	return 	<Container fluid>
				<Row>
					<Col className="d-flex justify-content-center">
						<Card className="mt-5 bg-white p-2 pt-4" style={{ width: "380px" }}>
							<Card.Body className="text-center">
								<img src={ logo } alt="Logo" style={{ height: "48px" }}/>
								<div>
									<h5 className="mt-3">{ showSuccess ? "We have sent you your password reset link" : "Start the password reset process" }</h5>
								</div>
								<div className="mb-3">
									<small className="text-black-50">{ showSuccess ? "Please check your email to reset your password." : "Enter your email address" }</small>
								</div>
								<Form>
									<Stack gap={3}>
										{
											!showSuccess &&
											<>
												<Form.Control 
													value={ username } 
													onChange={ e => setUsername(e.target.value) } 
													disabled={ submitting } 
													placeholder="Email" 
												/>
												{
													showError &&
													<small className="text-danger">An error ocurred attempting to start the password reset process. Please try again later.</small>
												}
												<Button 
													onClick={ onSubmitClicked } 
													disabled={ submitting }
												>
													{ submitting ? "Sending..." : "Send Link" }
												</Button>
											</>
										}
										<Button 
											onClick={ e => document.location.href = "/" } 
											disabled={ submitting }
											variant="light"
											className="border"
										>
											Back
										</Button>
									</Stack>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
}