import { Container } from "react-bootstrap";
import Sidebar from "../Common/Sidebar";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import OpportunitiesIndex from "./Opportunities/OpportunitiesIndex";
import OrdersIndex from "./Orders/OrdersIndex";
import ServicesIndex from "./Services/ServicesIndex";
import TopBar from "../Common/TopBar";

export default function Main() {
	return 	<Container fluid className="overflow-hidden h-100 p-0">
				<HashRouter>
					<TopBar />
					<Sidebar />
					<div style={{ marginLeft: "259px" }} className="h-100">
						<Routes>
							<Route path="/Opportunities" element={ <OpportunitiesIndex></OpportunitiesIndex> }></Route>
							<Route path="/Orders" element={ <OrdersIndex></OrdersIndex> }></Route>
							<Route path="/Services" element={ <ServicesIndex></ServicesIndex> }></Route>
							<Route path="*" element={ <Navigate to="/Opportunities" /> }></Route>
						</Routes>
					</div>
				</HashRouter>
			</Container>
}