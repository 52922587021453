import { Container } from "react-bootstrap";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

export default function Auth() {
	return 	<Container fluid className="overflow-hidden h-100 p-0">
				<HashRouter>
					<Routes>
						<Route path="/" element={ <Login></Login> }></Route>
						<Route path="/forgot-password" element={ <ForgotPassword></ForgotPassword> }></Route>
						<Route path="/reset-password/:code" element={ <ResetPassword></ResetPassword> }></Route>
						<Route path="*" element={ <Navigate to="/" /> }></Route>
					</Routes>
				</HashRouter>
			</Container>
}