import { Button, Col, Form, FormGroup, FormLabel, FormSelect, Modal, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { useEffect, useState } from "react";
import Spinner from "../../Common/Spinner";

export default function UsageStatisticsModal({ show, clientServiceId, onClose }) {
	const store = useSessionStore();
	const [statistics, setStatistics] = useState(null);
	const [dateRange, setDateRange] = useState("7");

	async function getStatistics(range) {
		setStatistics(null);
		const result = await store.ChannelPartnerService.GetNetworkStats(clientServiceId, range);

		if(result && result.Success) {
			setStatistics(result.Data);
		}
	}

	useEffect(() => {
		if(show) {
			getStatistics("7");
		}
	}, [show]);

	function onDateRangeChanged(range) {
		setDateRange(range);
		getStatistics(range);
	}

	return 	<Modal 
				show={show}
				dialogClassName="usage-modal"
			>
				<Modal.Header>
					<h4>Usage Statistics</h4>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<FormGroup>
							<FormLabel>Period</FormLabel>
							<FormSelect 
								value={ dateRange } 
								onChange={ e => onDateRangeChanged(e.target.value) }
								disabled={ !statistics }
							>
								<option value="7">7 Days</option>
								<option value="30">30 Days</option>
								<option value="90">90 Days</option>
							</FormSelect>
						</FormGroup>
					</Form>
					<hr />
					{
						statistics &&
						<Row>
							<Col>
								<h2>{ statistics.SiteName ? statistics.SiteName + " (" + statistics.Username + ")" : statistics.Username }</h2>
								<h4>{ statistics.Address ? statistics.Address : "" }</h4>
								<Row>
									<Col>
										<h5 className="pt-3">Bandwidth</h5>
										<img alt="Bandwidth" src={ `data:image/png;base64,${statistics.BandwidthBase64}` }></img>
									</Col>
								</Row>
								<Row>
									<Col>
										<h5 className="pt-3">Latency</h5>
										<img alt="Latency" src={ `data:image/png;base64,${statistics.LatencyBase64}` }></img>
									</Col>
								</Row>
							</Col>
						</Row>
						
					}
					<Spinner show={ !statistics } />
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={ onClose }>Close</Button>
				</Modal.Footer>
			</Modal>
}